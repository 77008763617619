import loadable from '@react-loadable/revised';
import SvgComet from 'assets/animator/commet.inline.svg';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import SeoLazy from 'components/SeoLazy';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import useInView from 'react-cool-inview';

const Planet = loadable({
	loader: () => import('assets/jsx/Planet'),
	loading: () => <div />
});

const LazyLoadIndex = loadable({
	loader: () => import('components/pages/be-an-animator/view/LazyLoadIndex'),
	loading: () => <div />
});

export default function AnimatorPage() {
	const { observe, unobserve, inView } = useInView({});
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(inView) {
			unobserve();
		}
	}, [inView]);

	return (
		<Layout>
			<div>
				<div className='layout relative'>
					<div className='hidden sm:block sm:w-70/100 xl:w-65/100 2xl:w-83/100 absolute sm:-right-5/100 xl:-right-15/100 2xl:-right-20/100 top-0 sm:pt-20 lg:pt-30 xl:pt-40'>
						<StaticImage src='../../../assets/animator/images/astronaut-desktop.png' alt="" placeholder='none' />
					</div>

					<h1 className='sm:text-shadow pt-40 sm:pt-60 lg:pt-80 xl:pt-120'>{t('pageAnimator.beAnimator.h1')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h1>

					<div className='w-full sm:w-50/100 2xl:h-160'>
						<p className='font-bold'>{t('pageAnimator.beAnimator.bold')}</p>
						<p>{t('pageAnimator.beAnimator.desc')}</p>
					</div>

					<div className='relative w-full mt-10 sm:hidden'>
						<StaticImage src='../../../assets/animator/images/astronaut-mobile.png' alt="" placeholder='none' />
						<Planet className='w-50 h-40 absolute left-0 top-0 mt-15' />
					</div>

					<div className='hidden sm:flex mt-20 ml-15 xl:mt-50 2xl:-mt-40 xl:ml-40'>
						<Planet className='w-140 h-100' />
						<div className='w-10 h-10 ml-10 lg:ml-30 lg:mt-30 xl:ml-80 xl:mt-40'>
							<StaticImage src='../../../assets/animator/circle.svg' alt="" placeholder='none' />
						</div>
						<div className='hidden 2xl:block 2xl:mt-150'>
							<SvgComet tw='w-60 h-60' />
						</div>
					</div>
				</div>
			</div>

			<div>
				<div className='layout relative sm:h-350 xl:h-400 3xl:h-550'>
					<div className='hidden sm:block w-53/100 absolute left-0 top-0 sm:-mt-50 sm:-ml-20'>
						<StaticImage src='../../../assets/animator/images/phone-dashboard-desktop.png' alt="" placeholder='none' />
					</div>

					<div className='sm:text-right'>
						<h2 className='mb-24 mt-80 sm:mt-60'>{t('pageAnimator.whatAnimatorIs.h2')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h2>
						<p ref={observe} className='w-full sm:w-50/100 sm:ml-auto'>
							{t('pageAnimator.whatAnimatorIs.desc')}<span className='font-bold'>{t('pageAnimator.whatAnimatorIs.bold')}</span>
							<div className='hidden sm:block w-85/100 ml-auto mt-20'>
								<StaticImage src='../../../assets/animator/images/astronaut-comet-desktop.png' alt="" />
							</div>
						</p>
					</div>

					{inView && (
						<div className='sm:hidden w-full mt-15'>
							<StaticImage src='../../../assets/animator/images/phone-dashboard-mobile.png' alt="" />
						</div>
					)}
				</div>
			</div>

			<SeoLazy
				isOpen={!inView}
				items={[
					{ id: 1, tag: 'h2', text: t('pageAnimator.benefitsAsAnimator.h2') },
					{ id: 2, tag: 'p', text: t('pageAnimator.benefitsAsAnimator.desc.1') },
					{ id: 3, tag: 'p', text: t('pageAnimator.benefitsAsAnimator.desc.2') },
					{ id: 4, tag: 'p', text: t('pageAnimator.benefitsAsAnimator.bold') },
					{ id: 5, tag: 'h2', text: t('pageAnimator.calculator.h2') },
					{ id: 6, tag: 'p', text: t('pageAnimator.calculator.desc') },
					{ id: 7, tag: 'p', text: t('pageAnimator.calculator.bold') },
					{ id: 8, tag: 'h2', text: t('pageAnimator.benefitsAsSupplier.h2') },
					{ id: 9, tag: 'p', text: t('pageAnimator.benefitsAsSupplier.desc') },
					{ id: 10, tag: 'h2', text: t('pageAnimator.benefitsAsSupplierList.h2') },
					{ id: 11, tag: 'h3', text: t('pageAnimator.benefitsAsSupplierList.ul.li.1') },
					{ id: 12, tag: 'h3', text: t('pageAnimator.benefitsAsSupplierList.ul.li.2') },
					{ id: 13, tag: 'h3', text: t('pageAnimator.benefitsAsSupplierList.ul.li.3') },
					{ id: 14, tag: 'h3', text: t('pageAnimator.benefitsAsSupplierList.ul.li.4') },
					{ id: 15, tag: 'h3', text: t('pageAnimator.benefitsAsSupplierList.ul.li.5') },
					{ id: 16, tag: 'h3', text: t('pageAnimator.benefitsAsSupplierList.ul.li.6') },
					{ id: 17, tag: 'h3', text: t('pageAnimator.benefitsAsSupplierList.ul.li.7') },
					{ id: 18, tag: 'h3', text: t('pageAnimator.benefitsAsSupplierList.ul.li.8') },
					{ id: 19, tag: 'p', text: t('pageAnimator.beginNow.ul.li.1') },
					{ id: 20, tag: 'p', text: t('pageAnimator.beginNow.ul.li.2') },
					{ id: 21, tag: 'p', text: t('pageAnimator.beginNow.ul.li.3') },
					{ id: 22, tag: 'p', text: t('pageAnimator.beginNow.ul.li.4') },
					{ id: 23, tag: 'p', text: t('pageAnimator.beginNow.desc.title') },
					{ id: 24, tag: 'p', text: t('pageAnimator.beginNow.desc.1') },
					{ id: 25, tag: 'p', text: t('pageAnimator.beginNow.desc.2') },
					{ id: 26, tag: 'p', text: t('pageAnimator.beginNow.desc.3') },
				]}
			/>

			{inView && <LazyLoadIndex />}
			<Footer />
		</Layout>
	);
}
